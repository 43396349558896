<template>
	<div class="page">
		<article :class="name" class="page__container">
			<aside id="aside">
				<div class="content">
					<div class="container">
						<header class="aside__head">
							<div class="head__thumbnail">
								<slot name="thumbnail"></slot>
							</div>
							<h1 class="head__title">
								<span class="double__line" v-html="title"></span>
								<div class="svg-container">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.67 61.25" width="100%" height="100%" v-bind:svg-inline="''" v-bind:class="'svg-content'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.19.2A7.14,7.14,0,0,0,.05,7.34V32.66a7.13,7.13,0,0,0,4.67,6.7L62.15,60.62a7.11,7.11,0,0,0,4.94,0l56.85-20.87a7.14,7.14,0,0,0,4.68-6.7V7.34A7.13,7.13,0,0,0,121.48.2Z" style="fill:#fff"/></svg>
								</div>
							</h1>
						</header>
						<div class="aside__body">
							<section class="section">
								<h2 class="title__h2">
									<svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 776.07 237.93" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <g>
    <path d="M6.71,48.22V48c0-21.85,17.24-39.75,41-39.75S88.41,25.93,88.41,47.78V48A39.47,39.47,0,0,1,81.6,70.08l3.3,2.74A8.33,8.33,0,0,1,88,79.19,7.92,7.92,0,0,1,74.46,84.9l-4.17-3.73a41.93,41.93,0,0,1-22.84,6.59C23.73,87.76,6.71,70.08,6.71,48.22Zm40,8.68a8,8,0,0,1,7.91-8,7.81,7.81,0,0,1,5.6,2.31l8.45,7.57a26.85,26.85,0,0,0,2.09-10.54V48c0-13.18-9.66-24.16-23.28-24.16S24.39,34.6,24.39,47.78V48c0,13.18,9.66,24.16,23.28,24.16a23.19,23.19,0,0,0,10.1-2.08l-8-6.81A8.33,8.33,0,0,1,46.68,56.9Z" style="fill: #fff"/>
    <path d="M155.18,78.75a8.35,8.35,0,1,1-16.69,0v-.66c-3.84,4.94-8.78,9.45-17.24,9.45-12.63,0-20-8.35-20-21.86V35.26a8.35,8.35,0,0,1,16.69,0V60.41c0,7.91,3.74,12,10.11,12s10.43-4.06,10.43-12V35.26a8.35,8.35,0,0,1,16.69,0Z" style="fill: #fff"/>
    <path d="M198,87.76c-17.68,0-30.74-12.41-30.74-30.42v-.22c0-16.81,12-30.64,29.1-30.64,19.65,0,28.33,16.14,28.33,28.33a7.62,7.62,0,0,1-7.8,8H183.85c1.64,7.58,6.92,11.53,14.38,11.53a19.27,19.27,0,0,0,11.86-4,6.24,6.24,0,0,1,4.07-1.32,6.38,6.38,0,0,1,6.48,6.59,6.56,6.56,0,0,1-2.2,4.94C213.28,85,206.58,87.76,198,87.76Zm10.77-35.37c-1-7.46-5.38-12.51-12.41-12.51S185,44.82,183.63,52.39Z" style="fill: #fff"/>
    <path d="M238.21,14a8.35,8.35,0,1,1,16.69,0V78.75a8.35,8.35,0,1,1-16.69,0Z" style="fill: #fff"/>
    <path d="M329.8,96.32a8.35,8.35,0,1,1-16.69,0V78c-4.07,5.49-9.67,9.56-18.34,9.56C281,87.54,268,76.77,268,57.12V56.9c0-19.66,12.85-30.42,26.8-30.42,8.89,0,14.38,4.06,18.34,8.78v-.11a8.35,8.35,0,0,1,16.69.11ZM284.66,56.9v.22c0,9.77,6.59,16.25,14.39,16.25s14.28-6.48,14.28-16.25V56.9c0-9.78-6.48-16.26-14.28-16.26S284.66,47,284.66,56.9Z" style="fill: #fff"/>
    <path d="M398.22,78.75a8.35,8.35,0,1,1-16.69,0v-.66c-3.85,4.94-8.79,9.45-17.25,9.45-12.62,0-20-8.35-20-21.86V35.26a8.35,8.35,0,0,1,16.69,0V60.41c0,7.91,3.73,12,10.1,12s10.44-4.06,10.44-12V35.26a8.35,8.35,0,0,1,16.69,0Z" style="fill: #fff"/>
    <path d="M441.05,87.76c-17.68,0-30.75-12.41-30.75-30.42v-.22c0-16.81,12-30.64,29.1-30.64,19.66,0,28.33,16.14,28.33,28.33a7.61,7.61,0,0,1-7.79,8H426.88c1.65,7.58,6.92,11.53,14.39,11.53a19.32,19.32,0,0,0,11.86-4,6.21,6.21,0,0,1,4.06-1.32,6.38,6.38,0,0,1,6.48,6.59,6.56,6.56,0,0,1-2.2,4.94C456.31,85,449.61,87.76,441.05,87.76Zm10.76-35.37c-1-7.46-5.38-12.51-12.41-12.51S428,44.82,426.66,52.39Z" style="fill: #fff"/>
    <path d="M478.94,80.84a6.66,6.66,0,0,1-3.19-5.71,6.6,6.6,0,0,1,9.89-5.71c5.6,3.51,11.2,5.27,15.92,5.27s7-1.76,7-4.39v-.22c0-3.63-5.71-4.84-12.19-6.81-8.24-2.42-17.57-6.26-17.57-17.68v-.22c0-12,9.66-18.67,21.52-18.67a41.45,41.45,0,0,1,18.23,4.5,6.8,6.8,0,0,1,4,6.26,6.57,6.57,0,0,1-6.7,6.59,6.1,6.1,0,0,1-3.08-.77C508,41,503.54,39.55,500,39.55c-4.06,0-6.15,1.75-6.15,4.06v.22c0,3.29,5.6,4.83,12,7,8.24,2.74,17.79,6.7,17.79,17.46v.22c0,13.07-9.77,19-22.51,19A41,41,0,0,1,478.94,80.84Z" style="fill: #fff"/>
  </g>
  <g>
    <path d="M156.63,183.59v-.33c0-25.14,19.22-45.84,46.17-45.84,13.31,0,22.68,3.61,30.07,9.86a11,11,0,0,1,3.95,8.54A11.23,11.23,0,0,1,225.48,167a11.75,11.75,0,0,1-7.23-2.47A23.81,23.81,0,0,0,202.64,159c-12.49,0-21.36,11-21.36,24v.32c0,13.48,8.71,24.32,22.34,24.32,6.58,0,11.67-2.3,16.44-6.08a11.65,11.65,0,0,1,6.73-2.3,10.6,10.6,0,0,1,6.91,18.57c-7.4,6.74-16.77,11.34-31.23,11.34C176,229.11,156.63,208.73,156.63,183.59Z" style="fill: #fff"/>
    <path d="M254.73,118.69a12.49,12.49,0,1,1,25,0v32.86c5.75-7.39,13.14-14.13,25.79-14.13,18.9,0,29.91,12.49,29.91,32.7v45.52a12.49,12.49,0,0,1-25,0V178c0-11.83-5.58-17.91-15.11-17.91s-15.61,6.08-15.61,17.91v37.63a12.49,12.49,0,0,1-25,0Z" style="fill: #fff"/>
    <path d="M356.77,118c0-7.23,6.08-11.67,14-11.67s14,4.44,14,11.67v.33c0,7.23-6.08,11.83-14,11.83s-14-4.6-14-11.83Zm1.48,32.53a12.49,12.49,0,0,1,25,0v65.08a12.49,12.49,0,0,1-25,0Z" style="fill: #fff"/>
    <path d="M411,160.42h-1a10.16,10.16,0,0,1-10.35-10.18A10.31,10.31,0,0,1,410,139.88h1V134.3c0-9.7,2.46-16.76,7.06-21.37s11.34-6.9,20.21-6.9a61.32,61.32,0,0,1,10.19.66,10.16,10.16,0,0,1,8.55,10,10.39,10.39,0,0,1-10.19,10.36h-2.14c-5.75,0-9,3-9,9.53v3.45h10.85a10.19,10.19,0,1,1,0,20.37H436v55.22a12.49,12.49,0,0,1-25,0Zm62.77,0h-1a10.16,10.16,0,0,1-10.35-10.18,10.31,10.31,0,0,1,10.35-10.36h1V134.3c0-9.7,2.46-16.76,7.06-21.37S492.17,106,501,106a61.32,61.32,0,0,1,10.19.66,10.16,10.16,0,0,1,8.55,10,10.39,10.39,0,0,1-10.19,10.36h-2.14c-5.75,0-9,3-9,9.53v3.45h10.85a10.19,10.19,0,1,1,0,20.37H498.74v55.22a12.49,12.49,0,0,1-25,0Z" style="fill: #fff"/>
    <path d="M532.27,150.56a12.49,12.49,0,0,1,25,0v3.95c3.62-8.55,9.53-16.43,16.93-16.43,7.72,0,12.16,5.09,12.16,12.16A11.49,11.49,0,0,1,577,161.9c-12.65,3-19.72,12.66-19.72,30.4v23.34a12.49,12.49,0,0,1-25,0Z" style="fill: #fff"/>
    <path d="M642.69,229.11c-26.46,0-46-18.57-46-45.52v-.33c0-25.14,17.91-45.84,43.54-45.84,29.42,0,42.4,24.15,42.4,42.39,0,7.23-5.09,12-11.67,12H621.49c2.47,11.34,10.35,17.25,21.53,17.25a28.85,28.85,0,0,0,17.75-5.91,9.26,9.26,0,0,1,6.07-2,9.55,9.55,0,0,1,9.7,9.86,9.79,9.79,0,0,1-3.29,7.39C665.53,225,655.51,229.11,642.69,229.11Zm16.1-52.91c-1.48-11.18-8-18.73-18.57-18.73-10.35,0-17.08,7.39-19.06,18.73Z" style="fill: #fff"/>
    <path d="M699.38,218.76a10,10,0,0,1-4.77-8.55,9.69,9.69,0,0,1,9.86-9.86,9.58,9.58,0,0,1,4.93,1.32c8.38,5.26,16.76,7.89,23.83,7.89,7.23,0,10.52-2.63,10.52-6.58v-.33c0-5.42-8.55-7.23-18.24-10.18-12.33-3.62-26.29-9.37-26.29-26.46v-.33c0-17.91,14.46-27.93,32.2-27.93a62,62,0,0,1,27.28,6.73,10.18,10.18,0,0,1,5.92,9.37,9.82,9.82,0,0,1-10,9.86,9.11,9.11,0,0,1-4.6-1.15c-7.07-3.45-13.8-5.59-19.06-5.59-6.08,0-9.2,2.63-9.2,6.08v.33c0,4.93,8.38,7.23,17.91,10.52,12.32,4.11,26.62,10,26.62,26.13v.32c0,19.56-14.63,28.43-33.69,28.43A61.39,61.39,0,0,1,699.38,218.76Z" style="fill: #fff"/>
  </g>
</svg>
								</h2>
								<div class="body">
									<h3 v-if="implementation">Date de mise en service</h3>
									<p v-if="implementation" class="text__big">{{implementation}}</p>
									<h3 v-if="highestChimney">Cheminée la plus haute</h3>
									<p v-if="highestChimney" class="text__big">{{highestChimney}} mètres</p>
									<h3 v-if="tonnesHandled">Nombre de tonnes traitées/heure</h3>
									<p v-if="tonnesHandled" class="text__big">{{tonnesHandled}}</p>
									<h3 v-if="processingTemperature">Température de traitement</h3>
									<p v-if="processingTemperature" class="text__big">{{processingTemperature}}°C</p>
								</div>
							</section>
							<section class="section">
								<h2 class="title__h2">
									<svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 745.15 269.85" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
  <g>
    <path d="M8.36,105.6,30,56.78a8.42,8.42,0,0,1,8-5.41h.8c3.72,0,6.38,2,7.88,5.41L68.25,105.6a6.53,6.53,0,0,1,.71,2.75,6.46,6.46,0,0,1-6.47,6.55c-3.18,0-5.31-1.86-6.55-4.69l-4.17-9.75H24.48l-4.34,10.19a6.7,6.7,0,0,1-6.29,4.25,6.27,6.27,0,0,1-6.29-6.38A7.12,7.12,0,0,1,8.36,105.6Zm22.5-61.41-5.49-5.13a4.36,4.36,0,0,1-1.77-3.19c0-2,4.25-4.08,7.53-4.08a5.78,5.78,0,0,1,5.05,3l4.87,7.62a5,5,0,0,1,.89,2.4c0,1.41-1.42,2.3-3.37,2.3A9.89,9.89,0,0,1,30.86,44.19ZM46.72,88.41,38.13,67.94l-8.6,20.47Z" style="fill: #fff"/>
    <g>
      <path d="M215.1,182.46a16.39,16.39,0,1,1-32.78,0v-36c-8,10.78-19,18.76-36,18.76-26.95,0-52.61-21.13-52.61-59.73v-.43c0-38.6,25.23-59.73,52.61-59.73,17.47,0,28.25,8,36,17.25v-.21a16.39,16.39,0,0,1,32.78.21Zm-88.62-77.41v.43c0,19.19,12.93,31.91,28.24,31.91s28-12.72,28-31.91v-.43c0-19.19-12.72-31.91-28-31.91S126.48,85.64,126.48,105.05Z" style="fill: #fff"/>
      <path d="M349.43,148a16.39,16.39,0,1,1-32.77,0v-1.29c-7.55,9.7-17.25,18.54-33.85,18.54-24.8,0-39.25-16.39-39.25-42.91V62.57a16.39,16.39,0,0,1,32.78,0V112c0,15.53,7.33,23.5,19.83,23.5s20.49-8,20.49-23.5V62.57a16.39,16.39,0,0,1,32.77,0Z" style="fill: #fff"/>
      <path d="M373.15,105.91v-.43c0-33.2,26.74-60.16,62.75-60.16,35.79,0,62.31,26.52,62.31,59.73v.43c0,33.21-26.73,60.16-62.74,60.16C399.67,165.64,373.15,139.12,373.15,105.91Zm92.72,0v-.43c0-17-12.29-31.91-30.4-31.91-18.76,0-30,14.45-30,31.48v.43c0,17,12.29,31.91,30.4,31.91C454.66,137.39,465.87,123,465.87,105.91Z" style="fill: #fff"/>
      <path d="M521.5,19.88c0-9.49,8-15.31,18.33-15.31s18.33,5.82,18.33,15.31v.43c0,9.49-8,15.53-18.33,15.53s-18.33-6-18.33-15.53Zm1.94,42.69a16.39,16.39,0,0,1,32.78,0V148a16.39,16.39,0,0,1-32.78,0Z" style="fill: #fff"/>
    </g>
    <path d="M659.68,176.55c-8.53,0-14.7-6.46-15.87-14.4l-2.94-20c-1.47-9.7,4.11-16.46,13.52-17.63C680,121.3,691.71,113.37,691.71,99v-.59c0-13.22-10-20.87-27-20.87-12.63,0-23.51,3.82-34.09,11.76A22.59,22.59,0,0,1,617.36,94,20.83,20.83,0,0,1,596.78,73.4a20.56,20.56,0,0,1,7.65-15.87C620,44.6,639.69,36.37,665.55,36.37c42.32,0,71.12,21.75,71.12,60.83v.59c0,37.33-25.86,52.61-57.89,59.07l-.88,5.29c-1.18,7.64-7.35,14.4-15.87,14.4Zm0,19.4c14.69,0,25.56,10.87,25.56,25v.59c0,14.1-10.87,25-25.56,25s-25.57-10.88-25.57-25v-.59C634.11,206.82,645,196,659.68,196Z" style="fill: #fff"/>
    <g>
      <path d="M271.4,248.61,266,262c-1,2.37-2.27,3.44-4.75,3.44-3,0-9.26-2.91-9.26-5.6a5.14,5.14,0,0,1,1-2.8l6.57-9.59c-12.5-3.66-20.9-15.08-20.9-28.55v-.22c0-16.48,12.6-30.06,30.27-30.06,8.73,0,14.87,2.37,19.72,6.47a7.25,7.25,0,0,1,2.59,5.6,7.36,7.36,0,0,1-7.44,7.33,7.74,7.74,0,0,1-4.74-1.62,15.6,15.6,0,0,0-10.23-3.66c-8.19,0-14,7.22-14,15.73v.21c0,8.84,5.71,15.95,14.65,15.95a16.61,16.61,0,0,0,10.78-4,7.66,7.66,0,0,1,4.41-1.51,7,7,0,0,1,4.53,12.18A27.82,27.82,0,0,1,271.4,248.61Z" style="fill: #fff"/>
      <path d="M299.53,230.83v-.22c0-12.6,9.59-18.42,23.27-18.42a41,41,0,0,1,14.11,2.37v-1c0-6.79-4.2-10.56-12.39-10.56a37.52,37.52,0,0,0-11.31,1.62,7,7,0,0,1-2.37.43,6.67,6.67,0,0,1-6.79-6.68,6.79,6.79,0,0,1,4.42-6.36,50.23,50.23,0,0,1,18.42-3c8.95,0,15.41,2.37,19.51,6.47,4.3,4.31,6.24,10.67,6.24,18.42V240.2a7.86,7.86,0,0,1-8,7.87c-4.74,0-7.86-3.34-7.86-6.79v-.11c-4,4.42-9.49,7.33-17.46,7.33C308.47,248.5,299.53,242.25,299.53,230.83Zm37.6-3.77v-2.91A25.45,25.45,0,0,0,326.68,222c-7,0-11.32,2.8-11.32,8v.21c0,4.42,3.67,7,8.95,7C332,237.19,337.13,233,337.13,227.06Z" style="fill: #fff"/>
      <path d="M397.58,241.93a6.53,6.53,0,0,1-3.13-5.61,6.35,6.35,0,0,1,6.47-6.46,6.27,6.27,0,0,1,3.23.86c5.49,3.45,11,5.17,15.62,5.17s6.9-1.72,6.9-4.31v-.21c0-3.56-5.61-4.74-12-6.68-8.08-2.37-17.24-6.14-17.24-17.35v-.22c0-11.74,9.48-18.31,21.12-18.31a40.47,40.47,0,0,1,17.88,4.42,6.67,6.67,0,0,1,3.88,6.14,6.44,6.44,0,0,1-6.57,6.46,6,6,0,0,1-3-.75c-4.63-2.26-9.05-3.66-12.5-3.66-4,0-6,1.72-6,4v.22c0,3.23,5.5,4.74,11.74,6.89,8.08,2.7,17.46,6.57,17.46,17.13v.22c0,12.82-9.59,18.64-22.09,18.64A40.27,40.27,0,0,1,397.58,241.93Z" style="fill: #fff"/>
      <path d="M480.54,248.71c-17.34,0-30.17-12.17-30.17-29.84v-.22c0-16.48,11.75-30.06,28.56-30.06,19.28,0,27.79,15.84,27.79,27.8a7.48,7.48,0,0,1-7.65,7.87H466.64c1.62,7.43,6.79,11.31,14.12,11.31a18.88,18.88,0,0,0,11.63-3.88,6.15,6.15,0,0,1,4-1.29,6.26,6.26,0,0,1,6.36,6.46,6.41,6.41,0,0,1-2.16,4.85C495.52,246,489,248.71,480.54,248.71ZM491.1,214c-1-7.33-5.28-12.28-12.17-12.28s-11.21,4.85-12.5,12.28Z" style="fill: #fff"/>
      <path d="M519.23,197.21a8.19,8.19,0,1,1,16.37,0v2.59C538,194.2,541.85,189,546.7,189c5.06,0,8,3.34,8,8a7.54,7.54,0,0,1-6.14,7.65c-8.3,1.94-12.93,8.29-12.93,19.93v15.3a8.19,8.19,0,1,1-16.37,0Z" style="fill: #fff"/>
      <path d="M567,231V203.68h-.54a7,7,0,0,1,0-14H567v-7.22a8.19,8.19,0,1,1,16.37,0v7.22h7.22a7,7,0,1,1,0,14h-7.22v24.67c0,3.77,1.62,5.6,5.28,5.6.54,0,1.62-.1,1.94-.1a6.82,6.82,0,0,1,6.79,6.78,6.66,6.66,0,0,1-4.2,6.14,24.09,24.09,0,0,1-9.37,1.62C573.74,248.39,567,244.4,567,231Z" style="fill: #fff"/>
    </g>
  </g>
</svg>
								</h2>
								<div class="body">
									<p class="abstract" v-html="abstract"></p>
								</div>
							</section>
						</div>
					</div>
				</div>
				<div class="navigation">
					<nav>
						<ul>
							<li><span>Séparation</span></li>
							<li><span>Transformation</span></li>
							<li><span>Épuration</span></li>
							<li><span>Mélange</span></li>
						</ul>
					</nav>
				</div>
			</aside>
			<section id="overview">
				<header class="head">
					<span>fermer</span>
					<a href="javascript:void(0)" @click="close()">
						<svg width="100%" height="100%" viewBox="0 0 187 188" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'">
<path d="M93.7002 182.4C142.577 182.4 182.2 142.777 182.2 93.9C182.2 45.0228 142.577 5.40002 93.7002 5.40002C44.823 5.40002 5.2002 45.0228 5.2002 93.9C5.2002 142.777 44.823 182.4 93.7002 182.4Z" stroke="#E10533" stroke-width="9.3327" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M56.6001 56.9L130.8 131" stroke="#E10533" stroke-width="9.3327" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M130.8 56.9L56.6001 131" stroke="#E10533" stroke-width="9.3327" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>
					</a>
				</header>
				<div id="animation"></div>
			</section>
		</article>
	</div>
</template>

<script>

	require("@/assets/js/fittext.js");
	const getLazyFunctions = () => import("@/assets/js/functions.js");
	const lottie = () => import("lottie-web");
	import axios from 'axios';

	export default {
		name: 'PageLayout',
		data() {
			return {
				path: this.animation,
			}
		},
		props: {
			name: String,
			title: String,
			implementation: String,
			highestChimney: String,
			tonnesHandled: String,
			processingTemperature: String,
			abstract: String,
			animation: String,
		},
		mounted() {
			getLazyFunctions().then(response => response.default.imageLazy());
			this.init();
			this.fitText();
		},
		methods: {
			async loadJsonData(path) {
				return await axios.get("/" + path).then(response => {
					return response.data;
				});
			},
			async init() {
				let jsonData = await this.loadJsonData(this.path);
				lottie().then(response => {
					response.default.loadAnimation({
						container: document.getElementById('animation'),
						renderer: 'svg',
						loop: true,
						autoplay: true,
						animationData: jsonData
					});
				});
			},
			close() {
				this.$router.push('/');
			},
			fitText() {
				const title = document.getElementsByClassName('head__title')[0];
				window.fitText(title.getElementsByTagName('span'), 1.1);
			},
		},
	}
</script>

<style lang="scss">

.page {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .57);
	z-index: 10;
}

.page__container {
	display: flex;
	flex-direction: row;
	padding: 6rem;
	height: 100%;
	@media #{$mq-small} {
		padding: $base-padding*5 $base-padding*6 $base-padding*2;
	}
	@media #{$mq-xm} {
		padding: $base-padding*5 $base-padding*6 $base-padding*3;
	}
	@media #{$mq-medium} {
		padding: $base-padding*6 $base-padding*7 $base-padding*3;
	}
	@media #{$mq-large} {
		padding: $base-padding*7 $base-padding*10 $base-padding*5;
	}
	@media #{$mq-xlarge} {
		padding: $base-padding*10 $base-padding*16 $base-padding*7; //12
	}
	@media #{$mq-xxlarge} {
		padding: $base-padding*17 $base-padding*25 $base-padding*10;
	}
	@media #{$mq-xxxlarge} {
		padding: $base-padding*19 $base-padding*27 $base-padding*10;
	}
	@media #{$mq-xxxxlarge} {
		padding: $base-padding*25 $base-padding*35 $base-padding*14;
	}
}

// ---------------------------------------------------------------------------
// Content
// ---------------------------------------------------------------------------

#aside {
	color: var(--color-white);
	width: 30%;
	margin-right: .5rem;
	.content {
		height: calc(90% - .5rem);
		margin-bottom: .5rem;
		background-color: var(--color-red);
		border-radius: 20px;
		@media #{$mq-medium} {
			border-radius: 20px;
		}
		@media #{$mq-large} {
			border-radius: 25px;
		}
		@media #{$mq-xlarge} {
			border-radius: 35px;
		}
		@media #{$mq-xxlarge} {
			border-radius: 45px;
		}
	}
	.container {
		display: flex;
		flex-direction: column;
		height: 100%;
		margin: 0 $base-padding*1;
		@media #{$mq-small} {
			margin: 0 $base-padding*1;
		}
		@media #{$mq-xm} {
			margin: 0 $base-padding*1.25;
		}
		@media #{$mq-medium} {
			margin: 0 $base-padding*2;
		}
		@media #{$mq-large} {
			margin: 0 $base-padding*2;
		}
		@media #{$mq-xlarge} {
			margin: 0 $base-padding*3;
		}
		@media #{$mq-xxlarge} {
			margin: 0 $base-padding*5;
		}
		@media #{$mq-xxxlarge} {
			margin: 0 $base-padding*6;
		}
		@media #{$mq-xxxxlarge} {
			margin: 0 $base-padding*7;
		}
	}
	.navigation {
		height: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		background-color: var(--color-red);
		border-radius: 20px;
		overflow: hidden;
		@media #{$mq-medium} {
			border-radius: 20px;
		}
		@media #{$mq-large} {
			border-radius: 25px;
		}
		@media #{$mq-xlarge} {
			border-radius: 35px;
		}
		@media #{$mq-xxlarge} {
			border-radius: 45px;
		}
	}
}

// ---------------------------------------------------------------------------
// Illustration
// ---------------------------------------------------------------------------

#overview {
	width: 75%;
	padding: .75rem;
	background-color: var(--color-white);
	border-radius: 20px;
	@media #{$mq-small} {
		padding: 0.75rem;
	}
	@media #{$mq-medium} {
		padding: 1rem;
		border-radius: 20px;
	}
	@media #{$mq-large} {
		padding: 1.40625rem;
		border-radius: 25px;
	}
	@media #{$mq-xlarge} {
		padding: 1.875rem;
		border-radius: 35px;
	}
	@media #{$mq-xxlarge} {
		padding: 2.8125rem;
		border-radius: 45px;
	}
}

#bm {
	height: calc(100% - 1.567rem);
	@media #{$mq-small} {
		height: calc(100% - 1.567rem);
	}
	@media #{$mq-medium} {
		height: calc(100% - 2.09rem);
	}
	@media #{$mq-large} {
		height: calc(100% - 2.5375rem);
	}
	@media #{$mq-xlarge} {
		height: calc(100% - 3.92rem);
	}
	@media #{$mq-xxlarge} {
		height: calc(100% - 4.875rem);
	}
	@media #{$mq-xxxlarge} {
		height: calc(100% - 5.875rem);
	}
}

// ---------------------------------------------------------------------------
// Overview
// ---------------------------------------------------------------------------

#overview {
	.head {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		span {
			color: var(--color-red);
			@include fluid-size('font-size', 10px, 35px, $min_vw, $max_vw);
			font-weight: 700;
			margin-right: .5rem;
			@media #{$mq-large} {
				margin-right: 1rem;
			}
		}
		svg {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
		}
		a {
			font-size: 0;
			display: block;
			position: relative;
			width: 1rem;
			height: 0;
			padding: 0;
			padding-bottom: 1rem;
			@media #{$mq-small} {
				width: 1.567rem;
				padding-bottom: 1.567rem;
			}
			@media #{$mq-medium} {
				width: 2.09rem;
				padding-bottom: 2.09rem;
			}
			@media #{$mq-large} {
				width: 2.2375rem;
				padding-bottom: 2.2375rem;
			}
			@media #{$mq-xlarge} {
				width: 3.92rem;
				padding-bottom: 3.92rem;
			}
			@media #{$mq-xxlarge} {
				width: 4.875rem;
				padding-bottom: 4.875rem;
			}
			@media #{$mq-xxxlarge} {
				width: 5.875rem;
				padding-bottom: 5.875rem;
			}
		}
	}
}

// ---------------------------------------------------------------------------
// Aside
// ---------------------------------------------------------------------------

// Head
.aside__head {
	display: flex;
	flex-direction: column;
	margin: -3rem $base-padding*2.25 0;
	padding-bottom: .5rem;
	@media #{$mq-small} {
		margin: -3rem $base-padding*2.25 0;
	}
	@media #{$mq-xm} {
		margin: -3.5rem $base-padding*2.75 0;
	}
	@media #{$mq-medium} {
		margin: -4rem $base-padding*2 0;
	}
	@media #{$mq-large} {
		margin: -5rem $base-padding*3.5 0;
		padding-bottom: 1rem;
	}
	@media #{$mq-xlarge} {
		margin: -7rem $base-padding*5.5 0;
	}
	@media #{$mq-xxlarge} {
		margin: -9rem $base-padding*7 0;
		padding-bottom: 1.5rem;
	}
	@media #{$mq-xxxlarge} {
		margin: -10rem $base-padding*5.5 0;
	}
	@media #{$mq-xxxxlarge} {
		margin: -15rem $base-padding*7.5 0;
		padding-bottom: 2.5rem;
	}
}

// Thumbnail
.head__thumbnail {
	font-size: 0;
	height: 85px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 10px;
		@media #{$mq-medium} {
			border-radius: 15px;
		}
		@media #{$mq-large} {
			border-radius: 20px;
		}
		@media #{$mq-xxlarge} {
			border-radius: 25px;
		}
	}
	@media #{$mq-small} {
		height: 85px;
	}
	@media #{$mq-xm} {
		height: 110px;
	}
	@media #{$mq-medium} {
		height: 125px;
	}
	@media #{$mq-large} {
		height: 150px;
	}
	@media #{$mq-xlarge} {
		height: 225px;
	}
	@media #{$mq-xxlarge} {
		height: 275px;
	}
	@media #{$mq-xxxlarge} {
		height: 315px;
	}
	@media #{$mq-xxxxlarge} {
		height: 455px;
	}
}

// Main Title
.head__title {
	position: relative;
	line-height: 1;
	font-family: 'Helvetica bold condensed', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-65%);
		width: 100%;
		padding: $base-padding*1.25;
		text-align: center;
		color: var(--color-red);
		@include fluid-size('font-size', $min_font_h1, $max_font_h1, $min_vw, $max_vw);
		font-weight: 700;
		&.double__line {
			transform: translateX(-50%) translateY(-60%);
		}
	}
}

// Body
.aside__body {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.section {
		margin-bottom: .5rem;
		text-align: center;
		.title__h2 {
			max-width: 45%;
			margin: 0 auto;
			@include fluid-size('font-size', $min_font_h2, $max_font_h2, $min_vw, $max_vw);
			margin-bottom: $base-padding*.5;
			@media #{$mq-medium} {
				max-width: 45%;
			}
			@media #{$mq-large} {
				max-width: 55%;
			}
			&:after {
				content: '';
				display: block;
				width: 75px;
				height: 1px;
				margin: 0 auto;
				background-color: var(--color-white);
				@media #{$mq-xxlarge} {
					height: 3px;
				}
			}
		}
		&:first-of-type {
			.title__h2 {
				&:after {
					margin-top: .25rem;
				}
			}
		}
		&:last-of-type {
			margin-bottom: 0;
			.title__h2 {
				max-width: 35%;
				@media #{$mq-medium} {
					max-width: 35%;
				}
				@media #{$mq-large} {
					max-width: 40%;
				}
			}
		}
		.body {
			padding: 0 .5rem;
			line-height: 1;
			@media #{$mq-large} {
				line-height: 1.1;
			}
			@media #{$mq-xlarge} {
				line-height: 1.2;
			}
			@media #{$mq-xxxxlarge} {
				line-height: 1.3;
			}
			p.abstract {
				p {
					text-align: justify;
					margin-bottom: .5rem;
					&.illustration {
						text-align: center;
					}
					&:last-of-type {
						margin-bottom: 0;
					}
				}
				.legend p {
					text-align: center;
				}
			}
			img {
				max-width: 35%;
			}
		}
		@media #{$mq-small} {
			margin-bottom: $base-padding*0.25;
		}
		@media #{$mq-medium} {
			margin-bottom: $base-padding*0.5;
		}
		@media #{$mq-large} {
			margin-bottom: $base-padding*1.5;
		}
		@media #{$mq-xlarge} {
			margin-bottom: $base-padding*1.75;
		}
		@media #{$mq-xxlarge} {
			margin-bottom: $base-padding*2;
		}
		@media #{$mq-xxxxlarge} {
			margin-bottom: $base-padding*2.5;
		}
	}
}

// ---------------------------------------------------------------------------
// Navigation
// ---------------------------------------------------------------------------

.distillation {
	@include navigation(1, var(--color-red));
}
.alkylation {
	@include navigation(2, var(--color-red));
}
.viscoreducteur {
	@include navigation(2, var(--color-red));
}
.craqueur {
	@include navigation(2, var(--color-red));
}
.reformeur {
	@include navigation(2, var(--color-red));
}
.hydrodesulfuration {
	@include navigation(3, var(--color-red));
}

</style>